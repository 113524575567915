import React from 'react'
import './Brief.css';
import {Link} from "react-router-dom"

function Brief() {
  return (
    <div className='brief'>
        <h2>Getting proof of funds has never been easier</h2>  
         <div className='brief-btn'>
          <Link  to= {"https://wa.me/+447920641165"}> <button>Chat on WhatsApp</button> </Link> 
        </div>   
    </div>
  )
}

export default Brief