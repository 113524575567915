import React from 'react'
import './Work.css';
import {Link} from "react-router-dom"

function Work() {
  return (
    <div className='work' id="section-1">
         <div className='choose-header'>
            <h2>How it works</h2>
            <p>The proof of funds service works seemlessly in four (4) easy steps.</p>
        </div>

        <div className='work-con'>
            <div className='work-box'>
                <div><h2>01</h2></div>
                <p>Easy Application</p>
                <span>Our application process is straightforward. You can apply online  or reach out to our hotlines for assistance.</span>
            </div>
            <div className='work-box'>
                <div><h2>02</h2></div>
                <p>Quick Review</p>
                <span>You can rest assured about approval. We promptly review and approve your request.</span>
            </div>
            <div className='work-box'>
                <div><h2>03</h2></div>
                <p>Get Your Cash</p>
                <span>Once your KYC is completed, your funds will be deposited into your account within 24-48 business hours</span>
            </div>
            <div className='work-box'>
                <div><h2>04</h2></div>
                <p>Easy Renewals</p>
                <span>Renewing is hassle-free. There are no long-term contracts; you simply renew when necessary.</span>
            </div>      
        </div>

        <div className='work-btn'>
          <Link  to= {`https://forms.gle/Gj9VonsWGuugecEh9`}> <button>Apply Online</button> </Link> 
        </div>
    </div>
  )
}

export default Work