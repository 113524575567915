import React from 'react'
import './Choose.css'

function Choose() {
  return (
    <div className='choose'>
        <div className='choose-header'>
            <h2>Why Choose Us?</h2>
            <p>Get familiar with why we are your suitable option.</p>
        </div>

        <div className='choose-con'>
            <div className='choose-box'>
                <p> Choice of account</p>
                <span>We can provide funding for your current account, or assist in opening a new one either with our assistance or independently seamlessly</span>
            </div>
            <div className='choose-box'>
                <p>Best Rates</p>
                <span>Thanks to our robust affiliations, we are able to offer some of the most competitive rates available in the market.</span>
            </div>
            <div className='choose-box'>
                <p>Access more than N50m</p>
                <span>Now, you have the opportunity to access up to N50M for the defense of YOUR project or visa applications.</span>
            </div>
            <div className='choose-box'>
                <p>Quick Response</p>
                <span>We ensure disbursement within 24 hours; however, in certain instances, particularly with certain banks, it may extend to 24-48 hours</span>
            </div>
        </div>
    </div>
  )
}

export default Choose