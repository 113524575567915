import React from 'react'
import './Home.css';
import {Link} from "react-router-dom"
import Choose from '../../Components/Choose/Choose';
import Work from '../../Components/Work/Work';
import Brief from '../../Components/Brief/Brief';
import Faq from '../../Components/Faq/Faq';
import Fast from '../../Components/Fast/Fast';

function Home() {
  const handleClickScroll = () => {
    const element = document.getElementById('section-1');
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div>
        <div className='hero'>
            <div className='hero-overlay'>
               <h2>Get <strong>Proof Of Funds</strong> In Nigeria (Disbursement IN 24-48 Hours)</h2>
                <p>We'll transfer funds to your account within 24-48 hours. Our process is quick, dependable, and requires minimal documentation. We have the capability to fund accounts with over 50 million Naira, whether they're newly opened or existing.</p>
                <Link onClick={handleClickScroll}  to= {``}> <button>How it works</button> </Link>  
            </div>

            <div className='hero-img'>
              <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1710881356/Frame_2124_cosjea.png" alt="" />
            </div>
        </div>
        <Choose />
        <Work />
        <Faq />
        <Fast />
        <Brief />
       
    </div>
  )
}

export default Home

