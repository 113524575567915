import React, { useState } from "react";
import "./Contact.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

function Contact() {
  const toastOptions = {
    position: "top-right",
    autoClose: 8000,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
  };
  
  const defaultData = {
    fullName: "",
    email: "",
    message: "",
  };

  const [data, setData] = useState(defaultData);
  const [loading, setLoading] = useState(false);
  console.log(data);
  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const payload = {
        fullName: data.fullName,
        email: data.email,
        message: data.message,
        recipientEmail: "3ciconsultings@gmail.com",
      };
      const res = await axios.post(
        "https://smartwave-mail-api.onrender.com/send-contact-mail",
        payload
      );
      console.log(res);
      if (res.data.status === true) {
        setLoading(false);
        toast.success(res.data.message, toastOptions);
      }
      if (res.data.status === false) {
        setLoading(false);
        toast.error(res.data.error, toastOptions);
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        setLoading(false);
        toast.error(error?.response?.data?.message, toastOptions);
      } else {
        setLoading(false);
        toast.error("An error occured while sending the mail", toastOptions);
      }
    }
  };

  return (
    <div>
       <div className='contact'>
            <div className='contact-overlay'>
                <h2>Contact Us</h2>
            </div>
        </div>

      <div className="contact-con">
        <div className="contact-form">
          <form>
            <label>Name</label>
            <input type="text" name="fullName"
              onChange={(e) => handleChange(e)} placeholder="Enter your name" />
            <label className="pad">Email</label>
            <input
              type="email"
              name="email"
              onChange={(e) => handleChange(e)}
              placeholder="Enter your email address"
            />
            <label className="pad">Message</label>
            <textarea name="message" placeholder="Enter your message" 
              onChange={(e) => handleChange(e)}/>
           
            ,
            <input
              className="submit-btn"
              type="submit"
              onClick={handleSubmit}
              value={`${loading ? "Sending...." : "Send Message"}`}
            />
          </form>
        </div>
      </div>

      
       
   
    </div>
  );
}

export default Contact;
