import React from 'react'
import './Footer.css'

function Footer() {
  return (
    <div className='footer'>
      <p>3CI Proof of Fund</p>
      <span>Copyright © 2023. 3Ci Consulting Limited</span>
    </div>
  )
}

export default Footer