import React, { useState } from "react";
import './Navbar.css'
import {Link} from "react-router-dom"




const Navbar = () => {

    const [navOpen, setNavOpen] = useState(false)
   
    return (
       <div>
         <div className="navbar">
            <div className='logo'>
              <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1710745412/ww_g1u748.jpg" alt="" /> 
            </div>

           <ul className="nav-items">
              <li>
                 <Link className="nav-list" to= {`./`}> Home</Link>
              </li>
  
              <li>
                 <Link className="nav-list" to= {"https://www.3ciconsulting.com/contact"}>Contact Us</Link>
              </li>

              <li>
                 <Link className="nav-list" to= {"https://wa.me/+447920641165"}>Chat on WhatsApp</Link>
              </li>
           </ul>

  
            <div className='try-btn'>
               <Link  to= {`https://forms.gle/Gj9VonsWGuugecEh9`}> <button>Apply Online</button> </Link>  
               
             
                <div className='menu-toggle' onClick={() => setNavOpen(!navOpen)}>
                    <div className= {navOpen ? "hamBox hamBoxOpen" : "hamBox"}>
                        <span className={navOpen ? "lineTop spin" : "lineTop"}></span>
                        <span className={navOpen ? "lineBottom spin" : "lineBottom"}></span>
                    </div>
                </div>
            </div> 


            
            <div className='nav-overlay' style={{
                top: navOpen ? "0" : "-180%",
                transitionDelay : navOpen ? "0s" : "0s"
             }}>

                <ul className='nav-links'>
                    <li className='nav-item'>
                      <Link to= {`./`} onClick={() => setNavOpen(!navOpen)} style={{
                           top: navOpen ? "0" : "150px",
                           transitionDelay : navOpen ? "0" : "0s"   
                      }} >  Home</Link>
                       <div className='nav-wrapper'></div>
                    </li>  

                    <li className='nav-item'>
                      <Link to= {"https://www.3ciconsulting.com/contact"} onClick={() => setNavOpen(!navOpen)} style={{
                         top: navOpen ? "0" : "150px",
                         transitionDelay : navOpen ? "0s" : "0s" 
                      }}>Contact Us</Link>
                       <div className='nav-wrapper'></div>
                    </li> 

                    <li className='nav-item'>
                      <Link to= {"https://wa.me/+447920641165"} onClick={() => setNavOpen(!navOpen)} style={{
                         top: navOpen ? "0" : "150px",
                         transitionDelay : navOpen ? "0s" : "0s" 
                      }}>Chat on WhatsApp</Link>
                       <div className='nav-wrapper'></div>
                    </li>          
                </ul>
            </div>
         </div>

         
       </div>

      
      

    )
  }
export default Navbar
