import React from 'react'
import { Accordion, AccordionItem as Item } from "@szhsin/react-accordion";
import './Faq.css';

const AccordionItem = ({ header, ...rest }) => (
 <Item
   {...rest}
   header={
     <>
       {header}
       <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1706473468/icons8-add-48_ojrahe.png" className='acc-img' alt="Chevron Down" />
     </>
   }
  
 />
);



function Faq() {
  return (
    <div>
         <div className='head-text'>
             <h2>FAQs</h2>
         </div>
       
      <div className='faq'>
      <div className='header'>
         </div>
         {/* `transitionTimeout` prop should be equal to the transition duration in CSS */}
         <Accordion transition transitionTimeout={250}>
          <AccordionItem header="Which accounts do you fund?" initialEntered>
          We fund both the new and existing accounts and as well fund Investment proof of fund
          </AccordionItem>

          <AccordionItem header="What is Investment Proof of Fund?">
          It's a proof of fund that comes with funding your investment account with our partner banks 
          </AccordionItem>

          <AccordionItem header="What do I need for an account opening?">
            You need to provide your passport data page, NIN, passport photograph and current utility bill.
          </AccordionItem>

          <AccordionItem header="Where can I apply from?">
            You can apply from any part of the world. Our clients get proof of funds from Canada, UK, USA etc

          </AccordionItem>

          <AccordionItem header="What happens to the account I opened ?">
          The account is yours and you have full control over it for your daily transactions
          </AccordionItem>

        </Accordion>
      </div>


    </div>
  )
}

export default Faq