import React from 'react'
import './Fast.css';

function Fast() {
  return (
    <div className='fast'>
        <div className='fast-left'>
          <h2><strong>Fast and Reliable</strong> Documentation Process!</h2>

          
          <div className='value'>
            <div className='misval-text'>
               <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
               <p>Access to more than N50m</p>
            </div>
            <div className='misval-text'>
               <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
               <p>Get funded immediately</p>
            </div>
            <div className='misval-text'>
               <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
               <p>Enjoy best and competitive rates</p>
            </div>
            <div className='misval-text'>
               <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
               <p>Get disbursement within 24-48 hours</p>
            </div>
           
            <div className='misval-text'>
               <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1697884574/Group_596_gr1imv.png" alt="" />
               <p>Payment instructions based on client specification</p>
            </div>
          </div>
        </div>

        <div className='fast-right'>
          <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1710962196/friendly-good-looking-enthusiastic-black-charming-girl-headband-sweater-smiling-broadly-amused-pointing-left-awesome-place-showing-you-perfect-blank-space-advertisement-posing-yellow-background_epekir.jpg" alt="" /> 
        </div>
    </div>
  )
}

export default Fast